<template>
  <main
    @keyup="onKeyUp"
    v-if="!isModeTt">
    <div class="container -block">
      <!-- Header -->
      <header class="user__header">
        <h1 class="user__title">{{ $t('resto.user_signup_title')}}</h1>
      </header>

      <div class="user__body">
        <!-- Sign up -->
        <section class="user__login flow">
          <forms-subscribe
            :errors="errors"
            has-subscribe
            v-model="user"
          />

          <actions-button
            class="fill"
            :disabled="isSubscribing || !user.optInTc"
            :loading="isSubscribing"
            @click="onSubscribe"
            appearance="primary"
          >{{ $t('resto.action_confirm') }}</actions-button>
        </section>

        <!-- Login -->
        <section class="user-auth__alt">
          <div class="user-auth__label">{{ $t('resto.user_subscribe_login') }}</div>
          <actions-button
            :href="loginRoute"
            size="s"
          >{{ $t('resto.action_signin') }}</actions-button>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import FormsSubscribe from '../../components/forms/subscribe'
import MixinErrors from '@/helpers/errors'

export default {
  name: 'UserLoginView',

  inject: [
    '$user',
    '$localStorage'
  ],

  components: {
    FormsSubscribe
  },

  mixins: [
    MixinErrors
  ],

  data() {
    return {
      errors: {},
      key: 1,
      isSubscribing: false,
    }
  },

  computed: {
    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin';
    },

    loginRoute() {
      let name = this.$route.name.replace('signup', 'login')
      return { name }
    },

    confirmRoute() {
      return { name: 'sayl-front-user.home' }
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    onKeyUp(ev) {
      if(ev.code === 'Enter') {
        this.onSubscribe()
      }
    },

    onSubscribe() {
      this.isSubscribing = true
      this.errors = {}

      this.$user.subscribe({ user: this.user, password: this.user.password, passwordConfirm: this.user.passwordConfirmation })
        .then(() => this.$router.replace(this.confirmRoute).catch(() => {}))
        .catch((e) => this.handleErrors(e))
        .finally(() => {
          this.isSubscribing = false
          this.key++
        })
    }
  },

  mounted() {
    this.errors = {}

    if(this.user && this.user.isLogged) {
      this.$router.push({ name: 'sayl-front-user.home'})
        .catch(() => {})
    }
  }
}
</script>
