<template>
  <!-- Subscribe -->
  <div
    :class="classes"
    v-if="!loading">

    <!-- Error banner -->
    <notice-banner
      :description="errorsLogin"
      intent="danger"
      v-if="hasErrors"
    />

    <form
      class="user-forms-subscribe__body flow"
      :key="key"
      @submit="onSubmit">
      <!-- Guest form -->
      <div class="user-forms-subscribe__guest flow">
        <div class="group">
          <!-- Firstname -->
          <forms-input
            :autofocus="autofocus"
            autocomplete
            :errors="getErrors('firstname')"
            name="given-name"
            :placeholder="$t('resto.user_firstname_placeholder')"
            ref="firstname"
            :required="isFieldFirstnameRequired"
            @input="onRemoveError('firstname')"
            v-model="user.firstname"
          >{{ $t('resto.user_firstname') }}</forms-input>

          <!-- Lastname -->
          <forms-input
            autocomplete
            :required="isFieldLastnameRequired || isCreating || !hasGuest"
            :errors="getErrors('lastname')"
            name="family-name"
            ref="lastname"
            :placeholder="$t('resto.user_lastname_placeholder')"
            @input="onRemoveError('lastname')"
            v-model="user.lastname"
          >{{ $t('resto.user_lastname') }}</forms-input>
        </div>

        <div
          class="group"
          v-if="isFieldEmailActive || isFieldPhoneActive || !isCreating">
          <!-- Email -->
          <forms-input
            autocomplete
            :errors="getErrors('email')"
            name="email"
            :placeholder="$t('resto.user_email_placeholder')"
            ref="email"
            :required="isFieldEmailRequired || !hasGuest"
            type="email"
            @input="onRemoveError('email')"
            v-if="isFieldEmailActive || !hasGuest"
            v-model="user.email"
          >{{ $t('resto.user_email') }}</forms-input>

          <!-- Phone -->
          <forms-input
            autocomplete
            :errors="getErrors('phone')"
            :hint="$t('resto.user_phone_number_hint')"
            name="phone"
            :placeholder="$t('resto.user_phone_number_placeholder')"
            ref="phone"
            :required="isFieldPhoneRequired"
            @input="onRemoveError('phone')"
            v-if="isFieldPhoneActive || !hasGuest"
            v-model="user.phone"
          >{{ $t('resto.user_phone_number') }}</forms-input>
        </div>
      </div>

      <!-- Create account for later -->
      <forms-checkbox
        class="review-auth-create-account"
        v-if="hasGuest && !isModeTt"
        v-model="user.isCreating"
      >{{ $t('resto.user_create_account_for_later')}}</forms-checkbox>

      <!-- Password -->
      <forms-input
        :errors="getErrors('password')"
        name="password"
        :placeholder="$t('resto.user_password_placeholder')"
        ref="password"
        required
        type="password"
        @input="onRemoveError('password')"
        v-if="!hasGuest || isCreating"
        v-model="user.password"
      >{{ $t('resto.user_password') }}</forms-input>

      <!-- Password Confirm -->
      <forms-input
        :errors="getErrors('password_confirmation')"
        name="password-confirmation"
        :placeholder="$t('resto.user_password_confirmation_placeholder')"
        ref="password-confirmation"
        required
        type="password"
        @input="onRemoveError('password_confirmation')"
        v-if="!hasGuest || isCreating"
        v-model="user.passwordConfirmation"
      >{{ $t('resto.user_password_confirmation') }}</forms-input>

      <!-- Terms -->
      <forms-checkbox
        v-if="hasSubscribe"
        v-model="user.optInTc">
        <div class="user-forms-subscribe__terms-content">
          <span
            class="user-forms-subscribe__terms"
            v-html="$t('resto.checkout_review_terms_label')"
          ></span>

          <span
            class="user-forms-subscribe__terms-link"
            @click.stop.prevent="() => showTerms = true"
            v-html="$t('resto.checkout_review_terms_link')"
          ></span>
        </div>
      </forms-checkbox>

      <!-- Newsletter -->
      <div v-if="hasSubscribe && isNewsletterActive">
        <forms-checkbox v-model="user.optIn">
          <div class="user-forms-subscribe__terms-content">
            <span
              class="user-forms-subscribe__terms"
              v-html="$t('resto.checkout_review_newsletter')"
            ></span>
          </div>
        </forms-checkbox>
      </div>
    </form>

    <modal-terms
      :visible="showTerms"
      @close="() => showTerms = false"
      v-if="showTerms"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ModalTerms from '@/components/modal/terms'

import MixinFields from '@/modules/checkout/mixins/fields'
import MixinErrors from '@/helpers/errors'

export default {
  name: 'FormsSubscribe',

  components: {
    ModalTerms,
  },

  inject: ['$localStorage'],

  model: {
    prop: 'user',
    event: 'change',
  },

  props: {
    autofocus: {
      type: Boolean,
      default: true
    },

    errors: {
      type: Object | Array,
    },

    user: {
      type: Object,
      required: true,
    },

    hasGuest: {
      type: Boolean,
      default: false,
    },

    hasSubscribe: {
      type: Boolean,
      default: false,
    }
  },

  mixins: [
    MixinErrors,
    MixinFields,
  ],

  data() {
    return {
      key: 1,
      showTerms: false,
      isCreate: false,
      optin: false,
    }
  },

  computed: {
    ...mapGetters({
      oat: 'bootstrap/oat'
    }),

    errorsLogin() {
      return this.$t('resto.user_subscribe_error')
    },

    classes() {
      return {
        'user-forms-subscribe': true,
        'flow': true
      }
    },

    isCreating() {
      return this.$basil.get(this.user, 'isCreating', false)
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin';
    },

    isNewsletterActive() {
      return this.isFieldActive('has_newsletter')
    },

    isNewsletterOptout() {
      return this.isFieldActive('is_newsletter_opt_out')
    },

    isTCOptout() {
      return this.isFieldActive('is_terms_opt_out')
    },

    hasErrors() {
      return !this.$basil.isNil(this.errors) && !this.$basil.isEmpty(this.errors)
    },

    loading() {
      return this.user == null
    },
  },

  methods: {
    onSubmit(e) {
      e.preventDefault()
      return
    }
  },

  mounted() {
    this.user.password = null
    this.user.passwordConfirmation = null

    this.user.optInTc = this.isTCOptout
    this.user.optIn = this.isNewsletterOptout === true ? this.isNewsletterOptout : this.user.optIn

    if(!this.hasGuest) {
      this.isCreate = true
    }

    this.key++
  },
}
</script>
