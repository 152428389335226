import { mapGetters, mapState } from 'vuex'

export default {
  data(){
    return {
      emailPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    }
  },

  computed: {
    ...mapState({
      order: state => state['sayl-front.checkout'].order,
      service: state => state['sayl-front.service'].service,
    }),

    ...mapGetters({
      oat: 'bootstrap/oat'
    }),

    // Comment
    isFieldCommentActive(){
      return this.isFieldActive('has_comment')
    },

    isFieldCommentValid(){
      return true
    },

    // Email
    isFieldEmailActive(){
      return this.$basil.get(this.user, 'isCreating', false) ? 
              true : 
              this.isFieldActive('has_email')
    },

    isFieldEmailRequired() {
      return this.$basil.get(this.user, 'isCreating', false) ? 
              true : 
              this.isFieldRequired('is_email_required')
    },

    isFieldEmailValid(){
      let value = this.$basil.get(this.user, 'email', '') || ''
      let hasValue = value.trim().length > 0
      let isValid = this.emailPattern.test(value)
      
      return !this.isFieldEmailActive ||
            (this.isFieldEmailActive && !this.isFieldEmailRequired && (!hasValue || hasValue && isValid)) ||
            (this.isFieldEmailActive && this.isFieldEmailRequired && hasValue && isValid)
    },

    // Firstname
    isFieldFirstnameRequired(){
      return true
    },

    isFieldFirstnameValid(){
      return !this.isFieldFirstnameRequired ||
             (this.isFieldFirstnameRequired && !this.$basil.isNil(this.user.firstname) && this.user.firstname.length > 0)
    },

    // Lastname
    isFieldLastnameRequired(){
      return this.$basil.get(this.user, 'isCreating', false)
    },

    isFieldLastnameValid(){
      return !this.isFieldLastnameRequired ||
              (this.isFieldLastnameRequired && !this.$basil.isNil(this.user.lastname) && this.user.lastname.length > 0)
    },

    // Password 
    isFieldPasswordValid(){
      return !this.$basil.isNil(this.user.password) && this.user.password.length > 0
    },

    // Password confirmation
    isFieldPasswordConfirmationValid(){
      return !this.$basil.isNil(this.user.passwordConfirmation) && this.user.passwordConfirmation.length > 0
    },

    // Phone
    isFieldPhoneActive(){
      return this.$basil.get(this.user, 'isCreating', false) ? 
              true : 
              this.isFieldActive('has_phone')
    },

    isFieldPhoneRequired(){
      return this.isFieldRequired('is_phone_required')
    },

    isFieldPhoneValid(){
      let value = this.$basil.get(this.user, 'phone', '') || ''
      let hasValue = value.trim().length > 0
      let isValid = hasValue

      return !this.isFieldPhoneActive ||
             (this.isFieldPhoneActive && !this.isFieldPhoneRequired && (!hasValue || hasValue && isValid)) ||
             (this.isFieldPhoneActive && this.isFieldPhoneRequired && hasValue && isValid)
    }
  },

  methods: {
    isFieldActive(name) {
      let serviceName = this.$basil.get(this.service, 'name', null);
      let service = serviceName === 'pickup' ? 'base' : serviceName;

      let prop = this.$basil.get(this.oat, name, { all: false });
      let ret = this.$basil.get(prop, service, null);

      if(ret === null) {
        ret = prop.all;
      }
      return ret
    },

    isFieldRequired(name) {
      let serviceName = this.$basil.get(this.service, 'name', null);
      let service = serviceName === 'pickup' ? 'base' : serviceName;
      
      let prop = this.$basil.get(this.oat, name, { all: false });
      let ret = this.$basil.get(prop, service, null);

      if(ret === null) {
        ret = prop.all;
      }
      return ret
    },
  }
}
